//导入Vue的包
import Vue from "vue";
//导入VueRouter的包
import Router from "vue-router";
//本地存储名称
import localStoreName from "@/common/utils/localStore/localStoreNames";
//token工具类
import tokenUtils from "@/common/utils/token/tokenUtils";
//导入ElementUI
import {Message} from "element-ui";

//调用Vue.use()函数，使用VueRouter
Vue.use(Router);

//创建路由的实例对象
//指定相关路由，与组件之间的关系
const router = new Router({
	//配置对应的路由，与对应的组件的关系（也就是路由哈希地址，与组件之间的关系）
	routes: [
		
		//—————————————————————————————————————————系统相关—————————————————————————————————————————/
		{
			//默认路由
			path: "/",
			//重定向到首页
			redirect: "/sys/index",
		},
		
		{
			//首页
			path: "/sys/index",
			//路由名称
			name: "/sys/index",
			components: {
				//懒加载（绑定name）
				"/sys/index": () => import("@/components/pages/system/sysIndex/SysIndex.vue"),
			},
		},
		
		{
			//系统用户管理
			path: "/sys/system/userManager",
			//路由名称
			name: "/sys/system/userManager",
			components: {
				//懒加载（绑定name）
				"/sys/system/userManager": () => import("@/components/pages/system/sysUserManager/SysUserManager.vue"),
			},
		},
		
		{
			//系统角色管理
			path: "/sys/system/roleManager",
			//路由名称
			name: "/sys/system/roleManager",
			components: {
				//懒加载（绑定name）
				"/sys/system/roleManager": () => import("@/components/pages/system/sysRoleManager/SysRoleManager.vue"),
			},
		},
		
		{
			//系统资源管理
			path: "/sys/system/menuManager",
			//路由名称
			name: "/sys/system/menuManager",
			components: {
				//懒加载（绑定name）
				"/sys/system/menuManager": () => import("@/components/pages/system/sysMenuManager/SysMenuManager.vue"),
			},
		},
		
		{
			//字典管理
			path: "/sys/system/dictManager",
			//路由名称
			name: "/sys/system/dictManager",
			components: {
				//懒加载（绑定name）
				"/sys/system/dictManager": () => import("@/components/pages/system/sysDictManager/SysDictManager.vue"),
			},
		},
		
		{
			//用户日志管理
			path: "/sys/log/userLog",
			//路由名称
			name: "/sys/log/userLog",
			components: {
				//懒加载（绑定name）
				"/sys/log/userLog": () => import("@/components/pages/system/sysLogManager/SysLogManager.vue"),
			},
		},
		{
			//系统用户登录
			path: "/sys/user/login",
			//路由名称
			name: "/sys/user/login",
			components: {
				//懒加载（登录注册组件）
				"/sys/user/login": () => import("@/components/pages/system/sysLogin/SysLogin.vue"),
			},
		},
		//————————————————————————————————————————————————————————————————————————————————————————/
		
		
		//—————————————————————————————————————————业务相关—————————————————————————————————————————/
		//————————————————————————————————————————————————————————————————————————————————————————/
	],
	
	//路由解析方式：hash路由地址
	mode: "hash",
});

//前置导航守卫
router.beforeEach(async (to, from, next) => {
	//访问登录页时，直接放行
	if (to.path === '/sys/user/login') {
		//如果token为空，说明没有登录过
		if (!tokenUtils.getToken()) {
			next();
		}
		//已经登录过了，跳转到首页
		else {
			next('/sys/index');
		}
	}
	//判断token
	else {
		//token为空时
		if (!tokenUtils.getToken()) {
			//存储的token异常信息，不为空时
			if (tokenUtils.getTokenErrorMsg()) {
				//提示当前的消息
				Message.error(tokenUtils.getTokenErrorMsg());
			}
			//token异常信息为空时
			else {
				//弹出一个固定的消息
				Message.error("登录凭证已失效，请重新登录");
			}
			
			//跳转到登录
			next('/sys/user/login');
		}
		
		//token不为空时：
		else {
			//设置一下刷新页面，保持当前页面路由
			if (to.path !== from.path) {
				//保存当前的路由地址（功能：刷新页面时，保持当前路由时使用）
				localStorage.setItem(localStoreName.route_to_path_store, to.path);
				//直接放行
				next();
			}
			//刷新页面时保持当前页面，不进行跳转
			else {
				next(false);
			}
		}
	}
});

//向外共享路由的实例对象
export default router;
