<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<fragment>
		<template v-for="item in data">
			<el-menu-item v-if="!item.children" :key="item.id" :index="item.url"
			              @click.native="menuItemClick(item)">
				<i :class="item.icon"></i>
				<span slot="title">{{ item.name }}</span>
			</el-menu-item>
			
			<el-submenu v-if="item.children" :key="item.id" :index="item.url">
				<template slot="title">
					<i :class="item.icon"></i>
					<span slot="title">{{ item.name }}</span>
				</template>
				<SysLeftMenuListDuiGui :data="item.children"></SysLeftMenuListDuiGui>
			</el-submenu>
		</template>
	</fragment>
</template>

<!--Vue的JS相关-->
<script>
	import localStoreName from "@/common/utils/localStore/localStoreNames";
	import vuex from "@/common/vuex";
	
	export default {
		//页面名称
		name: "SysLeftMenuListDuiGui",
		
		//父子级关系时，组件之间的数据共享
		props: {
			data: {
				type: Array,
				default() {
					return [];
				},
			},
		},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {},
		
		//data属性数据
		data() {
			return {};
		},
		
		//事件函数
		methods: {
			/**
			 * 点击获取菜单项
			 * @param item
			 */
			menuItemClick(item) {
				const arr = {
					menuName: item.name,
					menuPath: item.url
				}
				//将数据，保存到Vuex中
				this.$store.commit("addTabInfo", arr);
			},
			
			
			/**
			 * 页面刷新时，处理函数
			 */
			isReload() {
				//将Vuex中,tab的数据，保存到sessionStorage中
				sessionStorage.setItem(localStoreName.tab_menu_list_store, JSON.stringify(this.$store.state.tabInfo));
				//将Vuex中，将当前用户对应当前菜单的按钮权限码，保存到Vuex中
				sessionStorage.setItem(localStoreName.use_btn_list_store, this.$store.state.userBtnCode);
			},
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
		},
		
		mounted() {
			//监测页面刷新
			window.addEventListener("unload", this.isReload);
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
</style>
