<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<div>
		<el-container>
			<!--左侧区域-->
			<!--这里的宽度一定要设置为collapse，这样收缩时会变成收缩宽度-->
			<el-aside width="collapse">
				<SysLeftNavMean :is-collapse="isCollapse"></SysLeftNavMean>
			</el-aside>
			
			<!--右侧区域-->
			<el-container style="height: 100vh">
				<!--右侧头部--上区域-->
				<el-header class="right-header-top">
					<!--展开与收缩区域-->
					<div style="float: left;">
						<!--展开-->
						<div class="collapse-style" v-show="isCollapse===false">
							<el-image :src="require('@/assets/image/isCollapse/展开.svg')" @click="isCollapseBtn"/>
						</div>
						
						<!--收缩-->
						<div class="collapse-style" v-show="isCollapse===true">
							<el-image :src="require('@/assets/image/isCollapse/收缩.svg')" @click="isCollapseBtn"/>
						</div>
					</div>
					
					<!--头部上区域，显示的菜单项-->
					<div>
						<SysRightTopShow></SysRightTopShow>
					</div>
				</el-header>
				
				<!--右侧头部--下区域区域-->
				<el-header class="right-header-bottom">
					<!--动态Tab菜单区域-->
					<SysTab></SysTab>
				</el-header>
				
				
				<!--右侧主体区域-->
				<el-main style="margin-top: 10px">
					<!--页面区域-->
					<div class="pages-style" style="height: 100%">
						<!--页面组件-->
						<router-view :name="$route.fullPath"></router-view>
					</div>
					
					<!--&lt;!&ndash;脚步区域&ndash;&gt;-->
					<!--<el-footer class="footer-style">-->
					<!--	😄😄😄😄😄😄😄😄😄-->
					<!--</el-footer>-->
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<!--Vue的JS相关-->
<script>
	//左侧菜单
	import SysLeftNavMean from "@/components/pages/system/sysLeftNavMenu/SysLeftNavMean.vue";
	//右侧头部-上区域-个人中心
	//import SysPersonalCenter from "@/components/pages/system/sysOthers/sysRightTop/SysPersonalCenter.vue";
	import SysRightTopShow from "@/components/pages/system/sysOthers/sysRightTop/SysRightTopShow.vue";
	//右侧头部-下区域-动态Tab菜单
	import SysTab from "@/components/pages/system/sysOthers/sysTabs/SysTabs.vue";
	import vuex from "@/common/vuex";
	
	export default {
		//页面名称
		name: "",
		computed: {
			vuex() {
				return vuex
			}
		},
		
		//父子级关系时，组件之间的数据共享
		props: {},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {SysLeftNavMean, SysRightTopShow, SysTab},
		
		//data属性数据
		data() {
			return {
				//展开与收缩的标识
				isCollapse: false,
			}
		},
		
		//事件函数
		methods: {
			/**
			 * 点击展开或收缩按钮后，标识符的状态
			 */
			isCollapseBtn() {
				//状态互反
				if (this.isCollapse === false) {
					this.isCollapse = true;
				} else {
					this.isCollapse = false;
				}
			}
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
	//去除主体区域默认的外边距
	/deep/ .el-card__body, .el-main {
		//设置为0
		padding: 0;
	}
	
	//右侧头部--上区域的样式
	.right-header-top {
		//弹性
		display: flex;
		//侧轴居中
		align-items: center;
		justify-content: space-between;
		//高度
		height: 50px !important;
		//底部盒子阴影（向下偏移2px的阴影间距）
		box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
		
		//展开与收缩的样式
		.collapse-style {
			//图标宽度
			width: 25px;
			//图标高度
			height: 25px;
			//手指特效
			cursor: pointer
		}
	}
	
	//右侧头部--下区域的样式
	.right-header-bottom {
		//弹性
		display: flex;
		//侧轴居中
		align-items: center;
		//主轴左对齐
		justify-content: start;
		//高度
		height: 40px !important;
		//底部盒子阴影（向下偏移3px的阴影间距）
		box-shadow: 0 2px 3px rgb(216, 220, 229);
	}
	
	//主体区域
	.el-main {
		//右侧页面的区域样式
		.pages-style {
			//宽度
			width: auto;
			//边距
			padding: 15px;
			//背景颜色
			background-color: #ffffff;
		}
		
		//右侧脚部区域的样式
		.footer-style {
			//弹性布局
			display: flex;
			//水平居中
			justify-content: center;
			//垂直居中
			align-items: center;
			//背景颜色
			background-color: #f7f7f7;
			//上边距
			margin-top: 400px
		}
	}

</style>
