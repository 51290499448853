<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<div>
		<div class="right-top-show-container">
			<!--全屏显示-->
			<el-image :src="require('@/assets/image/others/全屏.png')" @click="fullScreenBtn" class="full-screen-class"
			          title="全屏模式"/>
			<!--个人中心-->
			<SysPersonalCenter></SysPersonalCenter>
		</div>
	</div>
</template>

<!--Vue的JS相关-->
<script>
	//右侧头部-上区域-个人中心
	import SysPersonalCenter from "@/components/pages/system/sysOthers/sysRightTop/SysPersonalCenter.vue";
	import screenfull from "screenfull";
	
	export default {
		//页面名称
		name: "",
		
		//父子级关系时，组件之间的数据共享
		props: {},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {
			SysPersonalCenter
		},
		
		//data属性数据
		data() {
			return {
				fullscreen: false  // 是否全屏
			};
		},
		
		//事件函数
		methods: {
			/**
			 * 全屏模式的按钮事件
			 */
			fullScreenBtn() {
				screenfull.toggle();
				if (!screenfull.isEnabled) {
					this.$Message.error("该浏览器不支持全屏功能");
				}
				this.fullscreen = !this.fullscreen;
			}
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
	//父级容器的样式
	.right-top-show-container {
		//弹性
		display: flex;
		//水平居中
		justify-content: center;
		//垂直居中
		align-items: center;
		//间距
		gap: 20px;
	}
	
	//全屏的样式
	.full-screen-class {
		//宽度
		width: 20px;
		//高度
		height: 20px;
		//手指
		cursor: pointer;
	}
</style>
