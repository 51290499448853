//统一管理，所有在本地存储的store的名称
let localStoreName = {
	
	//————————————————————————————————————————————————————————————————————————/
	//当前的菜单路由
	route_to_path_store: "selectMenuToPath",
	
	//标签页的菜单项
	tab_menu_list_store: "tabMenuList",
	
	//当前用户对应当前菜单的按钮权限码
	use_btn_list_store: "useMenuList",
	//————————————————————————————————————————————————————————————————————————/
};

//将该对象暴露
export default localStoreName;
