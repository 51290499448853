//导入ElementUI
import {Message} from "element-ui";
//导入axios
import axios from "axios";
//token工具类
import tokenUtils from "@/common/utils/token/tokenUtils";
//引入vuex
import vuex from '@/common/vuex/index'


//创建axios的实例
const http = axios.create({
	//baseURL
	baseURL: process.env.VUE_APP_API_BASEURL,
	
	//请求头
	headers: {
		"Token": tokenUtils.getToken(),
	},
	
	//请求超时时间
	//timeout: 1000,
});

//请求拦截器
http.interceptors.request.use(
	//发送请求之前，做些什么...
	(config) => {
		//显示数据加载动画（本质是Vuex的状态）
		vuex.commit("editLoadingFlag", true);
		
		//设置统一请求访问token
		config.headers.token = tokenUtils.getToken();
		
		return config;
	},
	//请求错误时，做些什么...
	(error) => {
		//隐藏数据加载动画（本质是Vuex的状态）
		vuex.commit("closeLoadingFlag", false);
		return Promise.reject(error);
	},
);

//响应拦截器
http.interceptors.response.use(
	//请求成功时：
	//对响应数据做些什么
	(response) => {
		//隐藏数据加载动画（本质是Vuex的状态）
		vuex.commit("closeLoadingFlag", false);
		return response;
	},
	
	//请求错误时：
	// 针对响应错误代码，做些什么...
	(error) => {
		//隐藏数据加载动画（本质是Vuex的状态）
		vuex.commit("closeLoadingFlag", false);
		
		if (error.response) {
			let status = error.response.status;
			
			//400异常：
			if (status === 400) {
				//未登录，跳转到登录页
				Message.error("客户端请求无效！");
			}
			
			//401异常：
			if (status === 401) {
				Message.error("当前请求需要身份验证！");
			}
			
			//403异常：
			if (status === 403) {
				Message.error("当前请求没有访问权限！");
			}
			
			//404异常：
			if (status === 404) {
				Message.error("当前请求地址不存在！");
			}
			
			//500异常：
			if (status === 500) {
				//其它异常：
				if (error.response.data.message !== "No message available") {
					//这里的字符串判断，和【后端】提前约束好
					if (error.response.data.message === "登录凭证已失效，请重新登录") {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//保存当前的Token对应的异常信息
						tokenUtils.setTokenErrorMsg(error.response.data.message);
						//刷新页面
						location.reload();
					}
					if (error.response.data.message === "未能读取到有效Token") {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//保存当前的Token对应的异常信息
						tokenUtils.setTokenErrorMsg("登录凭证已失效，请重新登录");
						//刷新页面
						location.reload();
					}
					if (error.response.data.message === "登录已被顶下线，请重新登录") {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//保存当前的Token对应的异常信息
						tokenUtils.setTokenErrorMsg(error.response.data.message);
						//刷新页面
						location.reload();
					}
					if (error.response.data.message === "登录已被踢下线，请重新登录") {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//保存当前的Token对应的异常信息
						tokenUtils.setTokenErrorMsg(error.response.data.message);
						//刷新页面
						location.reload();
					}
					if (error.response.data.message === "登录凭证已被冻结，请重新登录") {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//保存当前的Token对应的异常信息
						tokenUtils.setTokenErrorMsg(error.response.data.message);
						//刷新页面
						location.reload();
					} else {
						Message.error(error.response.data.message);
					}
				} else {
					Message.error("服务器异常");
				}
			}
			
			//其它自定义异常：
			else {
				Message.error(error.response.data.message || "服务器异常！");
			}
		} else {
			//请求超时或者网络异常，弹出错误信息
			Message.error("请求超时，无法连接服务器！");
		}
		return Promise.reject(error);
	}
);

//向外共享axios的实例对象
export default http;
