//导入vue
import Vue from 'vue'
//导入vuex
import Vuex from 'vuex'
//引入工具类库lodash
import lodash from 'lodash'
import localStoreName from "@/common/utils/localStore/localStoreNames";

//挂载vuex
Vue.use(Vuex);

const vuex = new Vuex.Store({
	//定义vuex的数据仓库（理解成组件中的data）
	state: {
		//格式为：key:value
		
		//接口请求的Loading动画标识
		isLoadingFlag: false,
		
		//获取当前用户,在该路由中的按钮权限
		userBtnCode: sessionStorage.getItem(localStoreName.use_btn_list_store),
		
		//页面标签的数据
		tabInfo: sessionStorage.getItem(localStoreName.tab_menu_list_store) ? JSON.parse(sessionStorage.getItem(localStoreName.tab_menu_list_store)) : [{
			menuName: "首页",
			menuPath: "/sys/index"
		}],
	},
	
	//定义state仓库中的数据修改（同步类型的修改）（理解成组件中的methods，methods操作的一般都是data）
	//注意：这个里面都要写成函数的形式
	mutations: {
		/**
		 * 修改接口请求的Loading动画标识
		 *
		 * @param state
		 * @param data
		 */
		editLoadingFlag(state, data) {
			state.isLoadingFlag = data;
		},
		/**
		 * 修改接口请求的Loading动画标识
		 *
		 * @param state
		 * @param data
		 */
		closeLoadingFlag(state, data) {
			state.isLoadingFlag = data;
		},
		
		/**
		 * 获取当前用户,在该路由中的按钮权限
		 * @param state
		 * @param data
		 */
		getUserBtnCode(state, data) {
			state.userBtnCode = "";
			state.userBtnCode = data;
		},
		
		/**
		 * 添加tabInfo
		 * @param state
		 * @param data
		 */
		addTabInfo(state, data) {
			let total = 0;
			
			//检查是否有内容相同的对象存在
			state.tabInfo.forEach((item, index) => {
				if (data.menuName !== item.menuName && data.menuPath !== item.menuPath) {
					//total加一
					total += 1;
				}
			});
			
			//相等时，证明数据中没有重复数据
			if (total === state.tabInfo.length) {
				state.tabInfo.push(data);
			}
		},
		
		/**
		 * 删除当前的tab
		 * @param state
		 * @param data
		 */
		deleteLocalTab(state, data) {
			const arr = state.tabInfo;
			
			//为1时，证明只有一个标签页"首页"
			if (arr.length === 1) {
				//
			}
			//这里证明，肯定不是关闭的首页
			else {
				lodash.pullAt(arr, data);
				state.tabInfo = [];
				state.tabInfo = arr;
			}
		},
		
		/**
		 * 删除其它的tab
		 * @param state
		 * @param data
		 */
		deleteOthersTab(state, data) {
			const arr = state.tabInfo;
			const indexList = [];
			
			//为1时，证明只有一个标签页"首页"
			if (arr.length === 1) {
				//
			} else {
				//根据首页--关闭其他时（只保留首页）
				if (data === 0) {
					arr.forEach((item, index) => {
						if (index !== 0) {
							indexList.push(index);
						}
					});
				}
				//根据非首页--关闭其他时（只保留首页、当前执行的菜单）
				else {
					arr.forEach((item, index) => {
						if (index !== 0 && index !== data) {
							indexList.push(index);
						}
					});
				}
				
				lodash.pullAt(arr, indexList);
				state.tabInfo = [];
				state.tabInfo = arr;
			}
		},
		
		
		/**
		 * 删除所有的tab（只保留首页）
		 * @param state
		 */
		deleteAllTab(state) {
			const arr = state.tabInfo;
			const indexList = [];
			
			//为1时，证明只有一个标签页"首页"
			if (arr.length === 1) {
				//
			} else {
				//关闭所有时（只保留首页）
				arr.forEach((item, index) => {
					if (index !== 0) {
						indexList.push(index);
					}
				});
				
				lodash.pullAt(arr, indexList);
				state.tabInfo = [];
				state.tabInfo = arr;
			}
		}
	},
});

//向外共享vuex的实例对象
export default vuex;
