//导入封装好的js文件
import http from '@/common/utils/request/request'

//--------------------------菜单管理-----------------------------------
//获取左侧的菜单树数据
export const getLeftMenuTree = () => {
	return http({
		url: '/sys/menu/getLeftMenuTree',
		method: 'post'
	})
}


//菜单管理：获取菜单列表树（只展示顶级菜单以下的菜单，类型为【菜单】和【按钮】）
export const getMenuList = (data) => {
	return http({
		url: '/sys/menu/getMenuList',
		method: 'post',
		data: data
	})
}

//菜单管理：获取系统菜单树结构（展示所有菜单，包括顶级菜单，类型为【菜单】）
export const getMenuListNoBtn = (data) => {
	return http({
		url: '/sys/menu/getMenuListNoBtn',
		method: 'post',
		data: data
	})
}

//菜单管理：根据菜单的ID，获取当前菜单信息及父级菜单ID
export const getMenuInfoAndPidByMenuId = (data) => {
	return http({
		url: '/sys/menu/getMenuInfoAndPidByMenuId',
		method: 'get',
		params: data
	})
}

//菜单管理：新增菜单
export const addMenu = (data) => {
	return http({
		url: '/sys/menu/addMenu',
		method: 'post',
		data: data
	})
}

//菜单管理：编辑菜单
export const editMenu = (data) => {
	return http({
		url: '/sys/menu/editMenu',
		method: 'post',
		data: data
	})
}

//菜单管理：删除菜单
export const delMenuById = (data) => {
	return http({
		url: '/sys/menu/delMenuById',
		method: 'get',
		params: data
	})
}

//获取用户的菜单按钮识别码
export const getMenuBtnCodeByUrl = (data) => {
	return http({
		url: 'sys/menu/getMenuBtnCodeByUrl',
		method: 'post',
		data: data
	})
}
//-------------------------------------------------------------------


//--------------------------用户管理-----------------------------------
//获取系统用户列表
export const getSysUserList = (data) => {
	return http({
		url: '/sys/user/getSysUserList',
		method: 'post',
		data: data
	})
}

//新增系统用户
export const addSysUser = (data) => {
	return http({
		url: '/sys/user/addSysUser',
		method: 'post',
		data: data
	})
}

//编辑系统用户
export const editSysUser = (data) => {
	return http({
		url: '/sys/user/editSysUser',
		method: 'post',
		data: data
	})
}

//根据用户的id，获取当前用户的分配角色
export const getRoleListByUserId = (data) => {
	return http({
		url: '/sys/user/getRoleListByUserId',
		method: 'post',
		data: data
	})
}

//根据用户的id，删除系统用户
export const delSysUserByUserId = (data) => {
	return http({
		url: '/sys/user/delSysUserByUserId',
		method: 'post',
		data: data
	})
}
//-------------------------------------------------------------------


//--------------------------角色管理-----------------------------------
//获取角色列表
export const getRoleList = (data) => {
	return http({
		url: '/sys/role/getRoleList',
		method: 'post',
		data: data
	})
}

//新增角色
export const addRole = (data) => {
	return http({
		url: '/sys/role/addRole',
		method: 'post',
		data: data
	})
}

//编辑角色
export const editRole = (data) => {
	return http({
		url: '/sys/role/editRole',
		method: 'post',
		data: data
	})
}

//根据角色的ID，获取当前角色的分配资源
export const getCheckedMenuByRoleId = (data) => {
	return http({
		url: '/sys/role/getCheckedMenuByRoleId',
		method: 'post',
		data: data
	})
}

//根据角色Id，删除角色数据
export const delRoleById = (data) => {
	return http({
		url: '/sys/role/delRoleById',
		method: 'post',
		data: data
	})
}
//-------------------------------------------------------------------


//--------------------------日志管理-----------------------------------
//获取用户登录日志列表
export const getUserLogList = (data) => {
	return http({
		url: '/sys/log/getUserLogList',
		method: 'post',
		data: data
	})
}
//-------------------------------------------------------------------


//--------------------------用户登录与注册、退出-------------------------
//系统用户登录
export const sysUserLogin = (data) => {
	return http({
		url: '/sys/user/sysUserLogin',
		method: 'post',
		data: data
	})
}

//用户注册
export const sysUserRegister = (data) => {
	return http({
		url: '/sys/user/sysUserRegister',
		method: 'post',
		data: data
	})
}

//用户退出
export const sysLogout = () => {
	return http({
		url: '/sys/logout/out',
		method: 'post'
	})
}

//获取系统随机验证码
export const getSysRandomCode = () => {
	return http({
		url: '/sys/user/getSysRandomCode',
		method: 'get'
	})
}
//----------------------------------------------------------------------
