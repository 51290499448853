<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<div>
		<!--个人中心-->
		<div class="personal-center-container">
			<!--个人中心下拉菜单-->
			<el-dropdown>
				<!--头像-->
				<el-avatar
						style="vertical-align: middle !important;"
						size="medium"
						src="http://okfiles.oss-cn-hangzhou.aliyuncs.com/imgs-test/2023-05-21/2023-05-21-cb340c0b20004e41b2d3e52a7293fb5d.png"
				></el-avatar>
				
				<!--下拉箭头-->
				<span style="vertical-align: middle !important;"><i
						class="el-icon-arrow-down el-icon--right"></i></span>
				
				<!--菜单项-->
				<el-dropdown-menu slot="dropdown">
					<!--个人信息-->
					<div>
						<el-dropdown-item>个人信息</el-dropdown-item>
					</div>
					
					<!--退出-->
					<div @click="sysLogoutBtn">
						<el-dropdown-item>退出</el-dropdown-item>
					</div>
				</el-dropdown-menu>
			</el-dropdown>
		</div>
	</div>
</template>

<!--Vue的JS相关-->
<script>
	import {sysLogout} from "@/common/utils/api/api";
	import tokenUtils from "@/common/utils/token/tokenUtils";
	import localStoreName from "@/common/utils/localStore/localStoreNames";
	
	export default {
		//页面名称
		name: "",
		
		//父子级关系时，组件之间的数据共享
		props: {},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {},
		
		//data属性数据
		data() {
			return {};
		},
		
		//事件函数
		methods: {
			/**
			 * 退出系统
			 */
			sysLogoutBtn() {
				//给出消息提示
				this.$confirm('是否退出登录？', '确认', {
					confirmButtonText: '是',
					cancelButtonText: '否',
					type: 'warning'
				}).then(() => {
					//调用接口
					sysLogout().then(response => {
						//清除本地缓存的失效token
						tokenUtils.delToken();
						//清除Vuex中，对应的Tab标签、用户按钮权限码对应的数据
						this.$store.commit("deleteAllTab");
						this.$store.commit("getUserBtnCode", "");
						//刷新页面
						location.reload();
					}).catch((error) => {
					});
				}).catch(() => {
				});
			}
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped></style>
