//系统统一token
module.exports = {
	/**
	 * 保存用户的token
	 *
	 * @param token
	 */
	saveToken(token) {
		//保存用户的登录Token
		localStorage.setItem('a9c63a2e07b54c539f9fc9c749558425', token);
	},
	
	/**
	 * 获取用户的Token
	 *
	 * @returns {string}
	 */
	getToken() {
		return localStorage.getItem('a9c63a2e07b54c539f9fc9c749558425');
	},
	
	/**
	 * 删除用户的token
	 */
	delToken() {
		localStorage.removeItem("a9c63a2e07b54c539f9fc9c749558425");
	},
	
	/**
	 * 保存后端返回的token异常信息
	 */
	setTokenErrorMsg(errorMsg) {
		localStorage.setItem("a9c63a2e07b54c539f9fc9c749558425_tokenErrorMsg", errorMsg);
	},
	
	/**
	 * 获取后端返回的token异常信息
	 */
	getTokenErrorMsg() {
		return localStorage.getItem("a9c63a2e07b54c539f9fc9c749558425_tokenErrorMsg");
	}
}

