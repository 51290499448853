import Vue from 'vue'
import App from './App.vue'
//路由
import Router from "@/common/router/index";
//elementUI组件库
import ElementUI from "element-ui";
//elementUI组件库对应的样式
import "element-ui/lib/theme-chalk/index.css";
//dataV组件库
import dataV from '@jiaminghi/data-view'
//基于elementUI的无限下级递归菜单栏
import NavMenu from 'element-navmenu_vue'
//引入Vue的fragment，用于解决导航菜单收缩时，无法隐藏文字和下拉箭头
import Fragment from 'vue-fragment'
//导入eventBus
import eventBus from "@/common/utils/event/eventBus";
//导入eventBus的名称管理
import eventBusName from "@/common/utils/event/eventBusName";
//引入bootstrap的CSS样式
import "bootstrap/dist/css/bootstrap.min.css";
//引入bootstrap的JS
import "bootstrap/dist/js/bootstrap.min.js";
//引入bootstrap库
import "bootstrap";
//引入jQuery库
import $ from "jquery";
//mavonEditor富文本
import mavonEditor from "mavon-editor";
//mavonEditor富文本的CSS样式
import "mavon-editor/dist/css/index.css";
//引入PassiveEventListeners事件，高版本的浏览器需要引入一下，防止控制台警告
import 'default-passive-events'
//引入工具类库lodash
import lodash from 'lodash'
//引入第三方鼠标右键单击，弹出菜单的组件
import contentMenu from 'v-contextmenu'
import 'v-contextmenu/dist/index.css'
//引入vuex
import store from '@/common/vuex/index'


//将elementUI组件库
Vue.use(ElementUI);
//dataV组件库
Vue.use(dataV)
//elementUI的无限下级递归菜单栏
Vue.use(NavMenu)
//fragment
Vue.use(Fragment.Plugin)
//mavonEditor富文本
Vue.use(mavonEditor);
//三方鼠标右键单击，弹出菜单的组件
Vue.use(contentMenu)

//eventBus的全局挂载
Vue.prototype.eventBus = eventBus;
//eventBus的事件名称全局挂载
Vue.prototype.eventBusName = eventBusName;
//挂载注册jQuery
Vue.prototype.$ = $;
//挂载工具类库lodash
Vue.prototype.lodashUtils = lodash;

new Vue({
	render: h => h(App),
	
	//挂载router路由
	//冒号后面的名称，注意和上面的import之后的名称保持一致
	router: Router,
	
	//挂载vuex
	store: store
}).$mount('#app')
