<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<div>
		<!--登录与注册-->
		<router-view v-if="!token" name="/sys/user/login"></router-view>
		
		<!--登录成功后，展示页面-->
		<!--注意：需要判断当前路由，是否为登录与注册的路由，从而防止渲染时显示矛盾-->
		<Show v-else v-show="this.$route.fullPath !=='/sys/user/login'"></Show>
	</div>
</template>

<!--Vue的JS相关-->
<script>
	import Show from "@/components/pages/system/Show.vue";
	//token工具类
	import tokenUtils from "@/common/utils/token/tokenUtils";

	export default {
		//页面名称
		name: "",
		
		//父子级关系时，组件之间的数据共享
		props: {},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {
			Show
		},
		
		//data属性数据
		data() {
			return {
				loading:true,
				
				//token（防止token失效后，刷新还是会访问接口）
				//只要token失效，直接根据它拦截掉
				token: null,
			};
		},
		
		//事件函数
		methods: {
			//获取token
			getToken() {
				this.token = tokenUtils.getToken();
			},
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
			//获取token
			this.getToken();
		},
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
</style>
