<template>
	<!--最大根节点-->
	<div>
		<div class="tags-wrapper">
			<el-tag class="tag-class"
			        v-for="(item,index) in $store.state.tabInfo"
			        :key="index"
			        size="medium"
			        :color="$route.fullPath === item.menuPath ? '#409eff' : '#ffffff'"
			        :hit="false"
			        :closable="item.menuPath!=='/sys/index'"
			        @click="clickTagBtn(item.menuPath)"
			        @close="closeCurrentBtn(index)"
			        v-contextmenu:tagMenuRef
			        :style="{
			          color: $route.fullPath === item.menuPath ? '#f2f3f6' : '#171d25'
                    }"
			        :class="$route.fullPath === item.menuPath ? 'isSpot' : ''"
			>
				{{ item.menuName }}
			</el-tag>
		</div>
		
		<!--标签页的右键点击时菜单项-->
		<v-contextmenu ref="tagMenuRef" @contextmenu="getNodeBtn">
			<div>
				<v-contextmenu-item class="tag-menu-item el-icon-close" @click="closeCurrentBtn(currentIndex)">
					关闭当前
				</v-contextmenu-item>
			</div>
			<div>
				<v-contextmenu-item class="tag-menu-item el-icon-circle-close" @click="closeOthersBtn(currentIndex)">
					关闭其它
				</v-contextmenu-item>
			</div>
			<div>
				<v-contextmenu-item class="tag-menu-item el-icon-circle-close" @click="closeAllBtn">
					关闭全部
				</v-contextmenu-item>
			</div>
		</v-contextmenu>
	</div>

</template>

<script>
	export default {
		//页面名称
		name: "",
		
		//父子级关系时，组件之间的数据共享
		props: {},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {},
		
		//data属性数据
		data() {
			return {
				//当前标签的索引值
				currentIndex: null
			}
		},
		
		//事件函数
		methods: {
			/**
			 * 点击标签
			 */
			clickTagBtn(menuPath) {
				//判断选择的标签，是否与当前的路由是一样的（一致时，不进行跳转；一致时，才进行跳转）
				if (menuPath !== this.$route.fullPath) {
					//路由跳转
					this.$router.push({name: menuPath}).then(() => {
					});
				}
			},
			
			/**
			 * 关闭当前标签
			 */
			closeCurrentBtn(index) {
				//删除当前标签
				this.$store.commit("deleteLocalTab", index);
				
				//获取关闭后，需跳转的路由
				const arr = this.$store.state.tabInfo;
				const formPath = arr[arr.length - 1].menuPath;
				
				//如果就是根据当前的标签，来进行执行的，则不进行路由跳转
				//原因：不再发起接口请求、防止重复路由报错
				if (formPath !== this.$route.fullPath) {
					//跳转到上一个路由页面
					this.$router.push({name: formPath}).then(() => {
					});
				}
			},
			
			
			/**
			 * 获取当前节点
			 */
			getNodeBtn(node) {
				//获取当前标签的节点，根据节点获取对应的key值（key值和index索引是对应的）
				this.currentIndex = node.key;
			},
			
			/**
			 * 关闭其它标签
			 */
			closeOthersBtn(node) {
				//删除其它标签
				this.$store.commit("deleteOthersTab", node);
				
				//获取关闭后，需跳转的路由
				const arr = this.$store.state.tabInfo;
				const formPath = arr[arr.length - 1].menuPath;
				
				//如果就是根据当前的标签，来进行执行的，则不进行路由跳转
				//原因：不再发起接口请求、防止重复路由报错
				if (formPath !== this.$route.fullPath) {
					//跳转到上一个路由页面
					this.$router.push({name: formPath}).then(() => {
					});
				}
			},
			
			/**
			 * 关闭全部标签
			 */
			closeAllBtn() {
				//删除全部标签
				this.$store.commit("deleteAllTab");
				
				//获取关闭后，需跳转的路由
				const arr = this.$store.state.tabInfo;
				const formPath = arr[arr.length - 1].menuPath;
				
				//如果就是根据当前的标签，来进行执行的，则不进行路由跳转
				//原因：不再发起接口请求、防止重复路由报错
				if (formPath !== this.$route.fullPath) {
					//跳转到上一个路由页面
					this.$router.push({name: formPath}).then(() => {
					});
				}
			},
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
	//标签区域的样式
	.tags-wrapper {
		//弹性
		display: flex;
		//主轴左对齐
		justify-content: flex-start;
		//侧轴居中
		align-items: center;
		//间距
		gap: 3px;
		
		//标签的样式
		.tag-class {
			//手指特效
			cursor: pointer;
			//字体加粗
			font-weight: 400;
		}
		
		//标签选中时，在标签的前面添加一个圆点
		.isSpot::before {
			//显示模式设置为块级
			display: inline-block;
			//空内容初始化
			content: '';
			//圆点的背景色
			background: #fff;
			//圆点的宽度
			width: 8px;
			//圆点的高度
			height: 8px;
			//圆角
			border-radius: 50%;
			//绝对定位
			position: relative;
			//右边距
			margin-right: 2px;
		}
		
		//标签中，"x"号的样式
		/deep/ .el-tag .el-icon-close::before {
			//叉号的颜色
			color: white;
		}
		
		//标签中，"x"号选择时的样式
		/deep/ .el-tag .el-icon-close:hover {
			//叉号的背景色颜色
			background-color: #b4bccc;
		}
	}
	
	//标签的菜单项的样式
	.tag-menu-item {
		//字体大小
		font-size: 12px;
		//字体加粗
		font-weight: 350;
	}
</style>
