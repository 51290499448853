//统一管理所有的eventBus中的名称
let eventBusName = {
	//——————————————————————————————————————————————————————————————————————————————————/
	//
	xx_Event:"xxx",
	//——————————————————————————————————————————————————————————————————————————————————/
};

//将该对象暴露
export default eventBusName;
