<!--DOM元素相关-->
<template>
	<!--最大根节点-->
	<div>
		<el-menu
				class="el-menu-style"
				:data="menuData"
				:default-active="settingMean.defaultActiveRoute"
				:collapse="isCollapse"
				:background-color="settingMean.menuBackgroundColor"
				:text-color="settingMean.menuTextColor"
				:active-text-color="settingMean.menuActiveTextColor"
				:router="settingMean.router"
				:collapse-transition=settingMean.collapseTransition
		>
			<!--菜单的logo区域-->
			<div class="menu-logo">
				<!--logo-->
				<el-image :src="require('@/assets/image/logo/sys_logo.png')"/>
				<!--文字描述-->
				<label v-show="isCollapse===false">&nbsp;🐮🐮</label>
			</div>
			
			<SysLeftMenuListDuiGui :data="menuData"></SysLeftMenuListDuiGui>
		</el-menu>
	</div>
</template>

<!--Vue的JS相关-->
<script>
	//引入菜单递归的组件
	import SysLeftMenuListDuiGui from "@/components/pages/system/sysLeftNavMenu/SysLeftNavMeanDiGui.vue";
	import localStoreName from "@/common/utils/localStore/localStoreNames";
	import {getLeftMenuTree} from "@/common/utils/api/api";
	
	export default {
		//页面名称
		name: "",
		
		//父子级关系时，组件之间的数据共享
		props: {
			isCollapse: {
				type: Boolean,
				default: () => false
			},
		},
		
		//相关组件（私有组件）
		//全局组件，请去根路径下的main.js中去进行注册
		components: {SysLeftMenuListDuiGui},
		
		//data属性数据
		data() {
			return {
				//菜单的基本设置
				settingMean: {
					//菜单区域的背景颜色
					menuBackgroundColor: "#304156",
					//菜单字体的默认颜色
					menuTextColor: "#fff",
					//选中菜单时的字体颜色
					menuActiveTextColor: "#409eff",
					//导航菜单收缩时，是否开启关闭过度动画。true：不关闭  false：关闭
					//注意：最好关闭，否则收缩时速度不够快，体验不好
					collapseTransition: false,
					//是否开启路由模式。true:开启 false:关闭
					router: true,
					//获取刷新前的路由地址，防止刷新后找不到相关页面
					defaultActiveRoute: ""
				},
				
				//菜单列表数据
				menuData: []
			};
		},
		
		watch: {
			//监听路由，将保存到sessionStorage中的路由地址，赋值给默认路由地址
			//原因：解决<el-main>中，若是根据跳转路由的方式，默认路由无法更新的问题（注意：若通过点击菜单的方式，默认路由没问题）
			$route(to, from) {
				//赋值给默认路由
				this.settingMean.defaultActiveRoute = localStorage.getItem(localStoreName.route_to_path_store)
			}
		},
		
		//事件函数
		methods: {
			/**
			 * 获取左侧的菜单树数据
			 */
			getMenuList() {
				//调用接口
				getLeftMenuTree().then(response => {
					//列表数据
					this.menuData = response.data.data.menuTree;
					
					//将用户对应当前菜单的按钮权限码，保存到Vuex中
					this.$store.commit("getUserBtnCode", response.data.data.btnCode);
				}).catch((error) => {
				});
			},
		},
		
		//生命周期函数（组件创建后）-->【常用】
		//可正常访问props、data、methods等（如：调用methods，发起axios请求，向data中存数据）
		created() {
			//获取菜单列表数据
			this.getMenuList();
		}
	}
</script>

<!--样式相关-->
<style lang="scss" scoped>
	//左侧菜单的宽度与高度（菜单展开时）
	.el-menu-style:not(.el-menu--collapse) {
		//宽度
		min-width: 200px;
		//菜单高度（这里为固定屏幕高度）
		height: 100vh;
	}
	
	//左侧菜单的宽度与高度（菜单收缩时）
	.el-menu-style:is(.el-menu--collapse) {
		//宽度
		width: 60px;
		//菜单高度（这里为固定屏幕高度）
		height: 100vh;
	}
	
	
	//菜单的logo区域
	.menu-logo {
		//宽度
		max-width: 230px;
		//高度
		height: 40px;
		//字体颜色
		color: #ffffff;
		//弹性布局
		display: flex;
		//水平居中
		justify-content: center;
		//垂直居中
		align-items: center;
		//背景色
		background-color: #304156;
	}
	
	//菜单的logo区域中的样式
	.menu-logo .el-image {
		//宽度
		width: 35px;
		//高度
		height: 35px;
		//字体大小
		font-size: 15px;
		//字体加粗
		font-weight: bold;
	}
</style>

